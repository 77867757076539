type TStage = {
  title: string;
  items: string[];
  color: string;
  img: {
    src: unknown;
    alt: string;
  };
};

export const stages: TStage[] = [
  {
    title: "Подготовительный этап",
    items: ["Выявление потребностей", "Определение целей и задач", "Фиксирование намерений"],
    color: "#9fc419",
    img: {
      src: require("@/assets/work/01.svg"),
      alt: "",
    },
  },

  {
    title: "Анализ",
    items: [
      "Сбор требований, описание задач",
      "Подготовка и выбор вариантов решений",
      "Планирование этапов и сроков работ",
      "Презентация и согласование дорожной карты",
    ],
    color: "#73c2af",
    img: {
      src: require("@/assets/work/02.svg"),
      alt: "",
    },
  },

  {
    title: "Проектирование",
    items: [
      "Описание решения",
      "Разработка архитектуры решения",
      "Разработка проектной документации",
    ],
    color: "#9294c9",
    img: {
      src: require("@/assets/work/03.svg"),
      alt: "",
    },
  },

  {
    title: "Разработка",
    items: ["Разработка системы", "Тестирование"],
    color: "#438dcc",
    img: {
      src: require("@/assets/work/04.svg"),
      alt: "",
    },
  },

  {
    title: "Запуск",
    items: ["Развертывание системы", "Подготовка отчетной документации", "Внедрение системы"],
    color: "#e83b3b",
    img: {
      src: require("@/assets/work/05.svg"),
      alt: "",
    },
  },

  {
    title: "Сопровождение",
    items: ["Гарантийное и постгарантийное обслуживание", "Услуги по сопровождению"],
    color: "#ff9900",
    img: {
      src: require("@/assets/work/06.svg"),
      alt: "",
    },
  },

  {
    title: "Развитие",
    items: ["Расширение функционала системы"],
    color: "#02a61c",
    img: {
      src: require("@/assets/work/07.svg"),
      alt: "",
    },
  },
];
