



















import { Component, Vue } from "vue-property-decorator";
import { stages } from "@/data/work";

@Component
export default class Work extends Vue {
  protected stages = stages;
}
